import { useTranslation } from "react-i18next";

import { Button, Message, Typography } from "@aviary";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@unauthenticated/shared/locales/i18n";

const AccountLockedMessage = () => {
  const { t } = useTranslation();
  const { isEmerson } = useSharedGlobalConfig();

  const supportLink = isEmerson
    ? sharedRoutes.external.emerson.support
    : sharedRoutes.external.support;

  const openSupportPage = () => {
    window.open(supportLink, "_blank");
  };

  const supportText = isEmerson
    ? l.signIn.contactEmersonSupport
    : l.signIn.contactFullscriptSupport;

  return (
    <Message titleContent={t(l.signIn.accountLocked)} isColor="danger">
      <Typography>{t(l.signIn.maximumAttemptsExceeded)}</Typography>
      <Button size="small" isColor="system" onClick={openSupportPage} isOutlined>
        {t(supportText)}
      </Button>
    </Message>
  );
};

export { AccountLockedMessage };
