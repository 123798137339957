import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

import type { AviaryLinkProps } from "@aviary/components/Link/Link";
import { Link } from "@aviary/components/Link/Link";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { useNavigate } from "@shared/react-router-dom";

import * as styles from "./BackButton.styles";

interface Props extends Pick<AviaryLinkProps, "to" | "onClick" | "isUnderLined"> {
  /*
   * To be used as link text
   */
  text: string;
  /*
   * To be used as link text
   */
  isMarginless?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/layouts/components/BackButton
 */
const BackButton = ({
  isMarginless = false,
  text,
  to,
  onClick,
  isUnderLined = true,
  ...rest
}: Props) => {
  const navigate = useNavigate();

  const linkStyles = [styles.backButton, !isMarginless && styles.margin];

  const handleBack = () => {
    if (to) return null;

    return navigate(-1);
  };

  return (
    <div css={styles.backButtonContainer}>
      <Link
        css={linkStyles}
        isColor="system"
        onClick={onClick ? onClick : handleBack}
        to={to}
        isUnderLined={isUnderLined}
        {...rest}
      >
        <FontAwesomeIcon css={styles.chevron} icon={faChevronLeft} aria-hidden />
        {text}
      </Link>
    </div>
  );
};

export { BackButton };
export type { Props as BackButtonProps };
