import { useTranslation } from "react-i18next";

import { Button, Cell, Typography } from "@aviary";
import { useCopyToClipboard } from "@shared/hooks/useCopyToClipboard/useCopyToClipboard";
import { l } from "@shared/locales/i18n";

import * as styles from "./BackupCode.styles";

interface Props {
  backupCode: string;
  copyButtonTextOverride?: string;
  isLoading?: boolean;
}

const BackupCode = ({ backupCode, copyButtonTextOverride, isLoading }: Props) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const copyBackupCode = async () => {
    await copyToClipboard(backupCode, { successMessage: t(l.auth.backupCodeCopied) });
  };

  return (
    <div css={styles.backupCodeWrapper}>
      <Cell css={styles.backupCode}>
        <Typography type="body" sizeOverride="h3" data-testid="backup-code" isSkeleton={isLoading}>
          {backupCode}
        </Typography>
      </Cell>
      <Button
        isOutlined
        isColor="system"
        size="small"
        onClick={copyBackupCode}
        isSkeleton={isLoading}
      >
        {copyButtonTextOverride || t(l.auth.copyCode)}
      </Button>
    </div>
  );
};

export { BackupCode };
