import { useExperimentVar } from "@shared/context/experiment/experiment.store";
import { useSearchParams } from "@shared/react-router-dom";

import { useExperiment } from "../useExperiment/useExperiment";
import { useFlippers } from "../useFlippers/useFlippers";
import { useSharedGlobalConfig } from "../useSharedGlobalConfig/useSharedGlobalConfig";

const useCallistoPracOnboardingExperiment = () => {
  const [isPracOnboardingEnabled] = useFlippers("callisto_prac_onboarding_2023Q4_experiment");
  const { analyticsAnonymousId, userId } = useExperimentVar();
  const { isEmerson } = useSharedGlobalConfig();
  const [searchParams] = useSearchParams();

  const step = searchParams?.get("step");

  const isExperimentEnabled = isPracOnboardingEnabled && !isEmerson && !!(step || userId);
  const group = useExperiment({
    experiment: "callisto_prac_onboarding_2024Q1_experiment",
    groups: ["CONTROL", "EXPERIMENT"],
    shouldLog: isExperimentEnabled,
    authedExperiment: false,
    unauthedExperiment: true,
    subjectId: analyticsAnonymousId,
    e2eGroup: "CONTROL",
  });

  return isExperimentEnabled && group === "EXPERIMENT";
};

export { useCallistoPracOnboardingExperiment };
