import { css, type Theme } from "@emotion/react";

export const orSection = (theme: Theme) => css`
  margin: 1rem 0;
  color: ${theme.system.borderHover};
  overflow: hidden;
  text-transform: uppercase;

  &:before,
  &:after {
    background-color: ${theme.system.borderHover};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 25%;
  }

  &:before {
    right: 0.5rem;
    margin-left: -50%;
  }

  &:after {
    left: 0.5rem;
    margin-right: -50%;
  }
`;
